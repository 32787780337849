import React from 'react'
import './HomePageQrSection.css'

function HomePageQrSection() {
  return (
    <div className='home_qr_section'>
        <div className="container" >
        </div>
    </div>
  )
}

export default HomePageQrSection