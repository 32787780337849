import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTarifData } from '../../../store/slices/TarifData/TarifDataApi'
import { selectTariffData } from '../../../store/slices/TarifData/TarifDataSlice'
import WavyBackground from '../WavyBackground'
import './HomePageTarifSection.css'
import { openTarifFeedbackModal, setTarifId } from '../../../store/slices/HomePageSlice/HomePageSlice'
import { useTranslation } from 'react-i18next'

function HomePageTarifSection() {
    const dispatch = useDispatch()
    const respTarifData = useSelector(selectTariffData)

    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getTarifData())
    }, [])

    const handleClick = (tarifId) => {
        dispatch(openTarifFeedbackModal())
        dispatch(setTarifId(tarifId))
    }

  return (
    <div className='home_page_tarif_section'>
        {
            respTarifData?.data && respTarifData?.data.map((item, index) => (
                <div key={item.id} className={`tarif-plan-general tarifPlan${item.id}`}>
                <WavyBackground  height="325px" text1={item.name} />
                <div className="tarif_plan2">
                  {/* <h4>{tarif.step}</h4> */}
                  <p>{item.desc}</p>
                  <p>{item.info_title}</p>

                  <div>
                      {
                        item.info_items.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))
                      }
                  </div>
                </div>
                {item.id !== 1 && <button className="tarif_button" style={{  fontSize: "14px", color: "white", border: "none", outline: "none", marginLeft: "95px", marginTop: "20px" }} onClick={() => handleClick(item.id)}>
                  {t('projectButtons.5')}
                </button>}
              </div>
            ))
        }
    </div>
  )
}

export default HomePageTarifSection