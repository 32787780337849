import React, { useEffect } from 'react';
import { CheckboxChecked, CheckboxNoChecked } from '../../../iconsFolder/icons';
import CustomBtnTikets from '../TicetsCustom/CustomBtnTikets';
import OneImage from './OneImage';
import { changePromNight_imgs_section_3, changePromNightFeedback } from '../../../store/slices/Tikets/tiketsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customBasesUrlFunc } from '../../../utils/helperFunck';
import { postWeeding1ResultMessage } from '../../../store/slices/Wedding1ResultMessageSlice/Wedding1ResultMessageApi';
import { wedding1ResultMessageSelector } from '../../../store/slices/Wedding1ResultMessageSlice/Wedding1ResultMessageSlice';

const FormBlockRight = ({ defaultImages_place, editStatusTemplate, item }) => {
   const [chechBox1, setChechBox1] = React.useState(false);
   const [chechBox2, setChechBox2] = React.useState(false);
   const [feedback, setFeedback] = React.useState('');
   const resultMessage = useSelector(wedding1ResultMessageSelector)

   const dispatch = useDispatch();
   const { t } = useTranslation();
   const hendleChechBox = (val) => {
      if (val) {
         setChechBox1(true);
         setChechBox2(false);
      } else {
         setChechBox1(false);
         setChechBox2(true);
      }
   };

   useEffect(() => {
      dispatch(changePromNightFeedback(feedback));
   }, [dispatch, feedback]);


   const hendleSubmit = async (e) => {
      e.preventDefault();
      const params = customBasesUrlFunc()

      const guest_name = e.target[0].value;
      const guest_quantity = e.target[1].value;
      const visit = e.target[2].checked ? 'yes' : e.target[3].checked ? 'no' : '';


      const message = {
         token: params?.token,
         visit,
         guest_name,
         guest_quantity: visit === 'yes' ? guest_quantity : '',  // Օգտագործեք դատարկ արժեք եթե visit='no'
      };

      if (params.token && guest_name && guest_quantity) {
         dispatch(postWeeding1ResultMessage(message))
         
      }
   };

   return (
      <div style={{ maxWidth: '280px', marginTop: '20px' }}>
         <div className="TiketsSection-blockRight-img">
            {/* <img src={defaultImages_place} alt="" /> */}
            {item ? item.images.map((img, index) => (
               <OneImage key={index} item={img} methodDispatch={changePromNight_imgs_section_3} />
            )) : <OneImage item={defaultImages_place} methodDispatch={changePromNight_imgs_section_3} alt="img" />}
            {editStatusTemplate && (
               <div className="iketsSection-blockRight-img-blockPhoneNumger">
                  <p style={{ fontSize: '16px' }}>{t('feedBackTitle')}</p>
                  <div className="iketsSection-blockRight-img-blockPhoneNumger-div">
                     <input type="text" placeholder={t('placeholderTikets.2')} onChange={(e) => setFeedback(e.target.value)} />
                  </div>
               </div>
            )}
            <p style={{ fontSize: '18px' }}>{t('tiketsFormTitles.0')}</p>
         </div>
         <form className="TiketsSection-blockRight-blockForm" onSubmit={hendleSubmit} >
            <div className="TiketsSection-blockRight-blockForm-name">
               <p style={{ fontSize: '16px' }}>{t('tiketsFormTitles.1')}</p>
               <input type="text" placeholder={t('placeholderTikets.0')} />
               <input type="text" placeholder={t('placeholderTikets.3')} />
            </div>
            <div className="TiketsSection-blockRight-blockForm-radio">
               <p style={{ fontSize: '16px' }}>{t('tiketsFormTitles.2')}</p>
               <div className="TiketsSection-blockRight-blockForm-radio-div">
                  <div onClick={() => hendleChechBox(true)}>
                     {/* {chechBox1 ? <CheckboxChecked /> : <CheckboxNoChecked />} */}
                     <input type="radio" name='result' value='yes' />
                     <label style={{ fontSize: '13px' }}>{t('tiketsFormTitles.3')}</label>
                  </div>
                  <div onClick={() => hendleChechBox(false)}>
                     {/* {chechBox2 ? <CheckboxChecked /> : <CheckboxNoChecked />} */}
                     <input type="radio" name='result' value='no' />
                     <label style={{ fontSize: '13px' }}>{t('tiketsFormTitles.4')}</label>
                  </div>
               </div>
               {resultMessage?.success && <span className="success">{resultMessage?.message}</span>}

               <div className="TiketsSection-blockRight-blockForm-btn">
                  <CustomBtnTikets btnText={t('tiketsButton.3')} />
               </div>
            </div>
         </form>
      </div>
   );
};

export default FormBlockRight;
