import React from 'react'
import './HomePageMiniFooter.css'
import { useTranslation } from 'react-i18next'
function HomePageMiniFooter() {
  const { t } = useTranslation()
  return (
    <div className='home_page_mini_footer'>
        <div className="container">
            <div className="home_page_mini_footer_block">
                <p>{t('homePageMiniFooter.0')}</p>
                <a href="https://webex.am" target="_blank">{t('homePageMiniFooter.1') == '' ? 'WEBEX TECHNOLOGIES' : 'WEBEX TECHNOLOGIES -ի'}</a>
                <p>{t('homePageMiniFooter.1')}</p>
            </div>
        </div>
    </div>
  )
}

export default HomePageMiniFooter