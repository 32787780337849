import React, { useRef, Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Stage, PresentationControls, Environment, OrbitControls } from '@react-three/drei';

const RotatingModel = ({ scene }) => {
   const ref = useRef();


   useFrame(() => {
      if (ref.current) {
         ref.current.rotation.y += 0.002;
      }
   });

   return <primitive ref={ref} object={scene} scale={0.05} />;
};

const CustomCanvas = ({ url }) => {
   const { scene } = useGLTF(url);
   return (
      <Canvas
         dpr={[1, 3]}
         shadows
         camera={{ fov: 45 }}
         style={{ width: '100px', height: '100px', background: 'transparent' }}
         gl={{ alpha: true }}>
         <ambientLight intensity={2} />
         <OrbitControls enableZoom={false} />
         <PresentationControls speed={1.5} global zoom={0.5} polar={[-0.1, Math.PI / 4]}>
            <Stage environment="sunset">
               <RotatingModel scene={scene} />
            </Stage>
         </PresentationControls>
         <Environment preset="sunset" />
      </Canvas>
   );
};

const LoadingFallback = () => (
   <div style={{ width: '100px', height: '100px', background: 'transparent' }}>
      Loading...
   </div>
);

const CustomCanvasWithSuspense = ({ url }) => (
   
   <Suspense fallback={<LoadingFallback />}>
      <CustomCanvas url={url} />
   </Suspense>
);

export default CustomCanvasWithSuspense;
