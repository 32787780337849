import React from 'react'
import { commentData } from '../../../dataFolder/data'
import './HomePageCommentSection.css'
import { useTranslation } from 'react-i18next'

function HomePageCommentSection() {
    const {t} = useTranslation()
    return (
        <div className='home_page_comment_section'>
            <div className="container">
                {
                    commentData.map((el,index) =>
                        <div className="home_page_comment_section_block" key={el.id}>
                            <div className="home_page_comment_section_block_img">
                                <img src={el.img} alt="" />
                            </div>
                            <div className="home_page_comment_section_block_text">
                                <h3>{t('homePageCommentsName.' + index)}</h3>
                                <p>{t('homePageCommentTexts.' + index)}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default HomePageCommentSection