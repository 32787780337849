export const wedding = require('./weeding.png');
export const male = require('./male.png');
export const female = require('./female.png');
export const cake = require('./cake.png');
export const birdLeft = require('./bird_left.png');
export const birdRight = require('./bird_right.png');
export const rings = require('./rings.png');
export const handLeft = require('./hand_left.png');
export const handRight = require('./hand_right.png');
export const headerBackground = require('./header_background.jpeg');
export const mainBackground = require('./main_background.jpeg');
export const redHeart = require('./red.png');
export const imageChurch = require('./imageChurch.jpg');
export const imageRestaurant = require('./imageRestaurant.jpg');
export const imageBride = require('./imageBride.jpg');
export const imageCouple = require('./imageCouple.jpg');
export const bouqetAnimation = require('./bouqetAnimation.gif');
export const footerBackground = require('./footerBackground.jpg');

export const image1 = require('../images/img1.png');
export const image2 = require('../images/img2.png');
export const image3 = require('../images/img3.png');

export const  background = require('./BirthDayImg/background.jpeg');

export const balloons = require('./BirthDayImg/balloons.png');

export const five = require('./BirthDayImg/five.png');

export const zero = require('./BirthDayImg/zero.png');