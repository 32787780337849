export const background = require('./background.jpeg');
export const balloons = require('./balloons.png');
export const invitation_1 = require('./messi.jpg');
export const invitation_2 = require('./messi_famely.jpg');
export const invitation_3 = require('./lionel-messi-antonela-roccuzzo.jpg');
export const restaurant_1 = require('./restaurant_1.png');
export const restaurant_2 = require('./restaurant_2.png');
export const restaurant_3 = require('./restaurant_3.png');
export const webex = require('./webex.png');
export const five = require('./five.png');
export const zero = require('./zero.png');

export const invitationImages = [
    invitation_1,
    invitation_2,
    invitation_3
]



export const resturantImages = [
    restaurant_1,
    restaurant_2,
    restaurant_3
]









