import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { birthDayChangeLinkModal, changeBirthDaySection_2_AddressLink } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice'

function BirthDayLinkModal() {
    const dispatch = useDispatch()
    const [link, setLink] = useState('')


    const handleClick = () => {
            dispatch(changeBirthDaySection_2_AddressLink(link))  
            dispatch(birthDayChangeLinkModal())
       
    }

  return (
    <div>
        <div className='wedding1LinkModal'>
        <div className='wedding1_link_modal_block'>
            <h1>Այստեղ պետք է տեղադրեք ձեր միջոցառման անցկացման վայրի հասցեի հղումը</h1>
            <input type="text" placeholder='https://yandex.com' value={link} onChange={(e)=>setLink(e.target.value)}/>
            <button onClick={handleClick}>Հաստատել</button>
        </div>
    </div>
    </div>
  )
}

export default BirthDayLinkModal