import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { galeriaIcon } from '../../../iconsFolder/icons';

function OneImage({ item, methodDispatch }) {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const { statusTemplate, editStatusTemplate } = useSelector((state) => state.tikets);

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        if (file) {
            const fileBlob = new Blob([file], { type: file.type });
            dispatch(methodDispatch(fileBlob));
        }
    }, [file, dispatch, methodDispatch]);

    const imageURL = file ? URL.createObjectURL(file) : (item?.path || item);

    return (
        <div className='one_img'>
            <img src={imageURL} alt="default img" />
            {editStatusTemplate && (
                <div className="download_div_imgs">
                    <label>
                        <div className='galeria_icon'>{galeriaIcon}</div>
                        <input style={{ display: 'none' }} type="file" onChange={handleChange} />
                    </label>
                </div>
            )}
        </div>
    );
}

export default OneImage;
