import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { allInfoSelector, changeInfoChrchesAddres, changeInfoChrchesText, changeInfoChurch_imgs, changeInfoChurchTime, changeInfoSelector } from '../../../../store/slices/ChangeInfoSlice/ChangeInfoSlice';
import { useTranslation } from 'react-i18next';
import { church, editIcon } from '../../../../iconsFolder/icons';
import { imageChurch } from '../../../../images/images';
import { openLinkModal } from '../../../../store/slices/LinkModalSlice/LinkModalSlice';
import { convertToBase64, customBasesUrlFunc } from '../../../../utils/helperFunck';
import TutorialVideo from '../../../TutorialVideo/TutorialVideo';

function Wedding1Church({item}) {
    const { t, i18n } = useTranslation();
    const [churchItem, setChurchItem] = useState(false);
    const churchRef = useRef(null);
    const allInfo = useSelector(allInfoSelector);
    const dispatch = useDispatch();
    const changeInfoState = useSelector(changeInfoSelector);
    const [churchTime, setChurchTime] = useState(allInfo.churchesTime)
    const [churchText, setChurchText] = useState(allInfo.chrchesText)
    const [churchAddress, setChurchAddress] = useState(allInfo.chrchesAddres)
    const [file, setFile] = useState('');
    const [churchAddressLink, setChurchAddressLink] = useState(allInfo.churchAddressLink)
    const params = customBasesUrlFunc();


    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (churchRef.current && offset > churchRef.current.offsetTop - 500) {
                setChurchItem(true);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        dispatch(changeInfoChurchTime(churchTime))
    },[churchTime])
    

    useEffect(() => {
        dispatch(changeInfoChrchesText(churchText))
    },[churchText])


    useEffect(() => {
        dispatch(changeInfoChrchesAddres(churchAddress))
    },[churchAddress])


    function handleChange(e) {
            setFile(e.target.files[0]);
     }

    useEffect(() => {

        if (file) {
            const fileBlob = new Blob([file], { type: file.type });
            dispatch(changeInfoChurch_imgs(fileBlob));
        }
      }, [file, dispatch]);

    return (
        <div className='church' ref={churchRef}>
            {
                churchItem &&
                <div className='marriage_text right'>
                    <span className='icon_marriage_church'>{church}</span>
                    <div className='about'>
                        <span className='marriage_title'>{t('marriage.0')}</span>
                        {changeInfoState === 'edit' ? <input className='event_inp' type="time" placeholder='Ժամ' value={allInfo.churchesTime} onChange={(e) => setChurchTime(e.target.value)} /> : <span>{allInfo.churchesTime || item.time}</span>}
                        {changeInfoState === 'edit' ? <input className='event_inp' type="text" placeholder={t('placeholderWedding1.2')} value={allInfo.chrchesText} onChange={(e) => setChurchText(e.target.value)} /> : <span>{allInfo.chrchesText || item.text}</span>}
                        {changeInfoState === 'edit' ? <input className='event_inp' type="text" placeholder={t('placeholderWedding1.3')} value={allInfo.chrchesAddres} onChange={(e) => setChurchAddress(e.target.value)}/> : <span>{allInfo.chrchesAddres ||item.address}</span>}
                    </div>

                    <div className='marriage_button_link_div'>
                        {
                            item.address_link && <a href={item.address_link} target='_blank'>
                                <button className='get even' onClick={() => !params.token &&  dispatch(openLinkModal('church'))}>{t('marriage.3')}</button>
                                
                            </a>
                        }

                        {
                            item?.type === 'local' && <TutorialVideo />
                        }
                   </div>
                </div>
            }
            {
                churchItem &&
                <div className='image_marriage left'>
                    {changeInfoState === 'edit' && <label onChange={(e) => handleChange(e)}>
                        <input style={{ display: 'none' }} type="file" />
                        <span className='wedding1_edite_image'>{editIcon}</span>
                    </label>}
                     {
                     changeInfoState && file ? <img src={URL.createObjectURL(file)} alt="wedding1" /> :
                      item?.images.map((img, index) => {
                        return <img key={index} src={img.path} alt="not found" />
                    })
                    }
                </div>
            }
        </div>
    )
}

export default Wedding1Church