import React, { memo, useEffect, useRef, useState } from 'react';
import { restaurant_1, restaurant_2, restaurant_3 } from '../../../images/BirthDayImg/Images';
import { useTranslation } from 'react-i18next';
import './Main_Restaurant.css';
import ResturantOneImage from '../ResturantOneImage/ResturantOneImage';
import { useDispatch, useSelector } from 'react-redux';
import { birthDayChangeLinkModal, birthDayInfoSelector } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import TutorialVideo from '../../TutorialVideo/TutorialVideo';

function Main_Restaurant({ section }) {
    const { t, i18n } = useTranslation();
    const [restaurantDisplay, setRestaurantDisplay] = useState(false);
    const restaurantRef = useRef(null);
    const [file, setFile] = useState(null);
    const birthDayInfo = useSelector(birthDayInfoSelector)


    const dispatch  = useDispatch()


    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (restaurantRef.current && offset > restaurantRef.current.offsetTop - 500) {
                setRestaurantDisplay(true);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    // useEffect(() => {
    //     dispatch(changeBirthDaySection_1_text(section1_text))
    // }, [section1_text, dispatch]);

    return (
        <div className='main_restaurant' ref={restaurantRef}>
            <div className='container'>
                <div className='main_restaurant_content'>
                    <h1>{section?.location_name || birthDayInfo?.section_1_location_name ||  t('main_restaurant.0')}</h1>
                    <div className='restaurant_navigate'>
                        <div className='restaurant'>
                           
                            {
                                restaurantDisplay && section?.images ? section?.images.map((image, index) => {
                                    if (index === 0) {
                                        return <div className={`item-${index+1}`} key={index}><img src={image.path} alt="not found" /></div>
                                    }
                                    else if (index === 1) {
                                        return <div className={`item-${index+1}`} key={index}><img src={image.path} alt="not found" /></div>
                                    }
                                    else {
                                        return <div className={`item-${index+1}`} key={index}><img src={image.path} alt="not found" /></div>
                                    }

                                }) : <>
                                    <ResturantOneImage idx={0} />
                                    <ResturantOneImage idx={1} />
                                    <ResturantOneImage idx={2} />
                                </>
                            }

                        </div>
                        <div className='navigate_button_main_restaurant'>
                            <a href={section?.address_link || "https://yandex.by/maps/10262/yerevan/?ll=44.552196%2C40.187364&mode=routes&rtext=40.195515%2C44.494510~40.177638%2C44.529983&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D175194505852&z=13"} target="_blank">
                                <button className='get' onClick={() => (!section?.address_link)  && dispatch(birthDayChangeLinkModal())}>{t('main_restaurant.1')}</button>
                            </a>
                            
                            {!section?.address_link &&  <TutorialVideo/>}

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Main_Restaurant)