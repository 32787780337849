import React from 'react'
import './TutorialVideo.css'

function TutorialVideo() {
    return (
        <div className="video_tutorial">
            <span>?</span>

            <div className="video_div">
                <video width={200} height={200} controls>
                    <source src="/tutorial.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default TutorialVideo