import { createSlice } from "@reduxjs/toolkit";
import { getHomePage, getPromoCode, postContact, postFeedback_Tarif } from "./HomePageApi";


const homePageSlice = createSlice ({
    name: 'homePage',
    initialState: {
        data: {},
        loading:'pending',
        promoCode:'',
        contactModal: false,
        tarifFeedbackModal: false,
        tarifObj: {
          tariff_id: '',
          feedback: ''
        },
        respTarif: {},
        contactMessage: '',

    },
    reducers: {
       openContactModal: (state) => {
          state.contactModal = true
       },

       closeContactModal: (state) => {   
          state.contactModal = false
       },

       openTarifFeedbackModal: (state) => {
        state.tarifFeedbackModal = true
     },

     tarifFeedbackModalClose: (state) => {
        state.tarifFeedbackModal = false
     },

     setTarifId: (state, action) => {
        state.tarifObj.tariff_id = action.payload
    },

    setFeedback: (state, action) => {   
        state.tarifObj.feedback = action.payload  
    }
    },

    extraReducers: (builder) => {
        builder
        .addCase(getHomePage.pending, (state, action) => {
             state.data = action.payload
             state.loading = 'pending'
        })
        .addCase(getHomePage.fulfilled, (state, action) => {
             state.data = action.payload
             state.loading = 'fulfilled'
        })
        .addCase(getHomePage.rejected, (state, action) => {
             state.data = action.payload
             state.loading = 'rejected'
        })

        // ==========================

        .addCase(getPromoCode.pending, (state, action) => {
            state.promoCode = action.payload
      
       })
       .addCase(getPromoCode.fulfilled, (state, action) => {
            state.promoCode = action.payload
          
       })
       .addCase(getPromoCode.rejected, (state, action) => {
            state.promoCode = action.payload
        
       })

     //   ==========================================

     .addCase(postFeedback_Tarif.pending, (state, action) => {
          console.log('pending');
     })   
     .addCase(postFeedback_Tarif.fulfilled, (state, action) => {
          state.respTarif = action.payload
        
     })
     .addCase(postFeedback_Tarif.rejected, (state, action) => {
          state.respTarif = action.payload
      
     })


     // ==================================================

     .addCase(postContact.pending, (state, action) => {
          console.log('pending');
     })   
     .addCase(postContact.fulfilled, (state, action) => {
          state.contactMessage = action.payload
        
     })
     .addCase(postContact.rejected, (state, action) => {
          state.contactMessage = action.payload
      
     })
     
    }
});

export const {openContactModal, closeContactModal, openTarifFeedbackModal, tarifFeedbackModalClose, setTarifId, setFeedback} = homePageSlice.actions;

export const homePageLoading = (state) => state.homePage.loading;

export const homePageSelector = (state) => state.homePage.data;

export const selectPromoCode = (state) => state.homePage.promoCode

export const selectContactModal = (state) => state.homePage.contactModal

export const selectTarifFeedbackModal = (state) => state.homePage.tarifFeedbackModal

export const selectTarifObj = (state) => state.homePage.tarifObj

export const selectRespTarif = (state) => state.homePage.respTarif

export const selectContactMessage = (state) => state.homePage.contactMessage

export default homePageSlice;
