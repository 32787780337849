import React, { useEffect, useState } from 'react';

import CustomBtnTikets from '../TicetsCustom/CustomBtnTikets';
import { changeInvitationName, changePromNightAddressLink, changePromNightText2, selectDefaultData } from '../../../store/slices/Tikets/tiketsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sectiosData } from '../../../dataFolder/data';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { useTranslation } from 'react-i18next';
const SectionDescript = ({ textArea_plesholder, editStatusTemplate, item }) => {
   const [linkModal, setLinkModal] = useState(false);
   const allInfoPromNight = useSelector(selectDefaultData);
   const [description, setDescription] = useState(allInfoPromNight.section_2_text);
   const [addressLink, setAddressLink] = useState(allInfoPromNight.address_link);
   const [invitationName, setInvitationName] = useState(allInfoPromNight.invitation_name)
   const respProject = useSelector(selectProjectData)
   const {t} = useTranslation()

   const dispatch = useDispatch();

   const handleRouteClick = () => {
     setLinkModal(true);

   };

   const handleRouteClose = () => {
      dispatch(changePromNightAddressLink(addressLink))
      setLinkModal(false);
   };

   useEffect(() => {
      dispatch(changePromNightText2(description))
   }, [description]);

   useEffect(() => {
      dispatch(changeInvitationName(invitationName))
   }, [invitationName]);


   // console.log(allInfoPromNight,888);
  
   return (
      <div className="TiketsSection-blockLeft-blockDescript">
         <h3 style={{fontSize: '35px'}}>{t('invitation')}</h3>
         {editStatusTemplate ? (
            <textarea className="TiketsSection-blockDescript-textarea" defaultValue={allInfoPromNight.section_2_text || item?.text || textArea_plesholder} onChange={(e) => setDescription(e.target.value)}></textarea>
         ) : (
            <p className="TiketsSection-blockDescript-defaulttext">{allInfoPromNight.section_2_text || item?.text || textArea_plesholder}</p>
         )}

         <div>
            {linkModal && editStatusTemplate  ? (
               <div className="TiketsSection-blockDescript-parLovation">
                  <p className="TiketsSection-blockDescript-parLovation-p">{t('tiketsButton.0')}</p>
                  <div className="TiketsSection-blockDescript-parLovation-inputBlock">
                     <p>
                        {t('tiketsAddresTitle')}
                     </p>
                     <div>
                        <input
                           type="text"
                           placeholder="https://www.google.com/maps/search/restoran+erevanum/@40.1792743,44.5252079,12z/data=!3m1!4b1?entry=ttu"
                           onChange={(e) => setAddressLink(e.target.value)}
                        />
                        <button onClick={handleRouteClose}>{t('tiketsButton.4')}</button>
                     </div>
                  </div>
               </div>
            ) : (
               <a href={item?.address_link || 'https://yandex.by/maps/157/minsk/?ll=27.555691%2C53.902735&mode=routes&rtext=&rtt=comparison&z=12'} target='_blank' onClick={handleRouteClick}>
                  <CustomBtnTikets btnText={t('tiketsButton.0')}/>
               </a>
               
            )}
         </div>
         {editStatusTemplate ? (
            <input className="TiketsSection-blockDescript-input" defaultValue={allInfoPromNight.invitation_name || item?.invitation_name} type="text" placeholder={t('placeholderTikets.0')} onChange={(e) => setInvitationName(e.target.value)}/>
         ) : (
            <p className="TiketsSection-blockDescript-defaultName">{allInfoPromNight.invitation_name || respProject?.data?.invitation_name || '12A'}</p>
            // console.log(item && item,87)
         )}
      </div>
   );
};

export default SectionDescript;
