import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import './HomeContactModal.css';
import { closeContactModal, selectContactMessage } from '../../../store/slices/HomePageSlice/HomePageSlice';
import { postContact } from '../../../store/slices/HomePageSlice/HomePageApi';
import { timeICon } from '../../../iconsFolder/icons';
import { useTranslation } from 'react-i18next';

function HomeContactModal() {
    const dispatch = useDispatch();
    const respContact = useSelector(selectContactMessage);
    const [showMessage, setShowMessage] = useState(false);
    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        name: yup.string().required(t('sliderContactValidation.0')),
        phone: yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, t('sliderContactValidation.1')).required(t('sliderContactValidation.0')),
        email: yup.string().email(t('sliderContactValidation.2')),
        message: yup.string(),
        time: yup.string(),
        type: yup.string(),
    });

    const handleContactFormSubmit = (values) => {
        dispatch(postContact(values));
        setShowMessage(true);
    };

    useEffect(() => {
        if (showMessage) {
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [showMessage]);

    return (
        <div className='home_contact_modal' onClick={() => dispatch(closeContactModal())}>
            <div className="home_contact_modal_block" onClick={(e) => e.stopPropagation()}>
                <h2 className="home_contact_modal_block_h2">{t('sliderContact.1')}</h2>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                        email: '',
                        message: '',
                        time: '',
                        type: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                        handleContactFormSubmit(values);
                        resetForm();
                    }}
                    validateOnBlur
                    validationSchema={validationSchema}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <form
                            className="home_contact_form"
                            onSubmit={handleSubmit}>
                            <div className="name-inp">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder={t('sliderContact.2')}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.name && errors.name && (
                                    <p className="error_formik">{errors.name}</p>
                                )}
                            </div>

                            <div className="phone-inp">
                                <input
                                    type="text"
                                    name='phone'
                                    placeholder={t('sliderContact.3')}
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.phone && errors.phone && (
                                    <p className="error_formik">{errors.phone}</p>
                                )}
                            </div>

                            <div className="email-inp">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={t('sliderContact.4')}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.email && errors.email && (
                                    <p className="error_formik">{errors.email}</p>
                                )}
                            </div>

                            <div className="message-inp">
                                <input
                                    type="text"
                                    name="message"
                                    placeholder={t('sliderContact.5')}
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.message && errors.message && (
                                    <p className="error_formik">{errors.message}</p>
                                )}
                            </div>

                            <div className="time-inp">
                                <input
                                    type="text"
                                    name="time"
                                    placeholder={t('sliderContact.6')}
                                    value={values.time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.time && errors.time && (
                                    <p className="error_formik">{errors.time}</p>
                                )}
                                <span className='timeICon'>{timeICon}</span>
                            </div>

                            <div className="radio_div">
                                {[t('sliderContact.7'), t('sliderContact.8'), t('sliderContact.9'), t('sliderContact.10')].map(type => (
                                    <label key={type}>
                                        <input
                                            type="radio"
                                            name="type"
                                            value={type}
                                            checked={values.type === type}
                                            onChange={() => setFieldValue('type', type)}
                                        />
                                        <p>{type}</p>
                                    </label>
                                ))}
                            </div>

                            {touched.type && errors.type && (
                                <p className="error_formik">{errors.type}</p>
                            )}

                            {showMessage && respContact.message && (
                                <p style={{ color: respContact.success ? 'white' : 'red' }}>{respContact.message}</p>
                            )}
                            <button type="submit" className="contact_home_form_btn">{t('sliderContact.11')}</button>

                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default HomeContactModal;
