import React, { useState } from 'react'
import { getPromoCode } from '../../../store/slices/HomePageSlice/HomePageApi';
import { selectPromoCode } from '../../../store/slices/HomePageSlice/HomePageSlice';
import { useDispatch, useSelector } from 'react-redux';
import './HomePagePromoCode.css'
import { useTranslation } from 'react-i18next';

function HomePagePromoCode() {
  const [showPromoMessage, setShowPromoMessage] = useState(false);
  const dispatch = useDispatch();
  const resPromoCode = useSelector(selectPromoCode);
  const {t} = useTranslation();

  const handleSendPromoCode = (e) => {
    e.preventDefault();
    dispatch(getPromoCode(e.target[0].value));
    e.target.reset();
    setShowPromoMessage(true);
  };

    return (
        <div className="home_prom_code">
                <div className='container'>
                    <h1 className='home_prom_code_title'>{t('homePagePromoCodeSection.0')}</h1>
                    <span className='home_prom_code_text'>{t('homePagePromoCodeSection.1')}</span>
                    <form onSubmit={handleSendPromoCode}>
                        <h5 className='home_prom_code_text_form'>{t('homePagePromoCodeSection.2')}</h5>
                        <div className='home_prom_code_input_div'>
                          <input type="text" placeholder="FN3" />
                          <button>{t('projectButtons.4')}</button>
                        </div>
                    </form>
                    {showPromoMessage && <p style={{ color: resPromoCode?.success ? 'green' : 'red' }}>{resPromoCode?.message}</p>}
                </div>
        </div>
    )
}

export default HomePagePromoCode