import React, { useRef, useState } from 'react'
import './InvitationLinkModal.css'
import { copyIcon, fbIcon, shareLinkedin, ShareTelegram, shareTwiter, shareViber, ShareWatsUp } from '../../iconsFolder/icons';
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, ViberShareButton, WhatsappShareButton } from 'react-share';
import { useTranslation } from 'react-i18next';

function InvitationLinkModal({ link, code, setInvitation_link_modal }) {
  const [textCopyed, setTextCopied] = useState(false);
  const { t } = useTranslation();
  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setTextCopied(true);
        setTimeout(() => setTextCopied(false), 1000);
      })
      .catch((err) => {
        setTextCopied(false);
      });
  };

  return (
    <div className='invitation_link_modal'>
      {code && <p>{t('invitation_modal.2')}{code}</p>}
      {link &&
        <div className='invitation_link_modal_block'>
          <div className='invitation_link_modal_text'>
            <span>{t('invitation_modal.0')}</span>
            <a href={link}>{link}</a>
          </div>
          <div className='invitation_link_modal_copy'>
            <span className='invitation_link_modal_copy_icon' onClick={() => handleCopyText(link)}>{copyIcon}</span>
            {textCopyed && <span className='invitation_link_modal_copy_text'>{t('invitation_modal.1')}</span>}
          </div>
        </div>
      }

      <div className="shares_div">
        <FacebookShareButton url={link} quote={''} className='items'>
          <span>{fbIcon} Facebook</span>
        </FacebookShareButton>

        <TwitterShareButton url={link} title={''} className='items'>
               <span>{shareTwiter} Twitter</span>
        </TwitterShareButton>

        <LinkedinShareButton url={link} title={''} className='items'>
               <span>{shareLinkedin} LinkedIn</span>
        </LinkedinShareButton>

        <TelegramShareButton url={link} title={''} className='items'>
               <span>{ShareTelegram} Telegram</span>
        </TelegramShareButton>

        <WhatsappShareButton url={link} title={''} className='items'>
               <span>{ShareWatsUp} WhatsUp</span>
        </WhatsappShareButton>

        <ViberShareButton url={link} title={''} className='items'>
               <span>{shareViber} Viber</span>
        </ViberShareButton>
      </div>
      <span className='invitaionModalXmark' onClick={() => setInvitation_link_modal(false)}>X</span>
    </div>
  )
}

export default InvitationLinkModal