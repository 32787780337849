
export {default as Ring} from './p1.png'
export {default as Watch} from './p2.png'
export {default as Teddy} from './p3.png'
export {default as Flower1} from './p4.png'
export {default as Folowe2} from './p5.png'
export {default as watch1} from './p6.png'
export {default as Watch2} from './p7.png'
export {default as Matani} from './p8.png'
export {default as Slide} from './slide.png'
export {default as Wedding1} from './Wedding1.jpeg'
export {default as Wedding2} from './Wedding2.jpeg'
export {default as Wedding3} from './wedding3.jpeg'
export {default as Wedding4} from './wedding4.jpeg'
export {default as Wedding5} from './wedding5.jpeg'
export {default as Wedding6} from './wedding6.jpeg'
export {default as Harsaniq} from './Harsaniq.png'
export {default as Harsaniq1} from './Harsaniq1.png'
export {default as Harsaniq2} from './Harsaniq2.png'
export {default as Harsaniq3} from './Harsaniq3.png'
export {default as Png} from './png.png'
export {default as Foto} from './Foto.png'
export {default as Mekn} from './Mekn1.png'
export {default as Mekn2} from './Mekn2.png'
export {default as Mekn3} from './Mekn3.png'
export {default as Footer} from './Footer.png'
export {default as Footer1} from './footer1.png'
export {default as Lang} from './Lang.png'
export {default as Language} from './Language.png'
export {default as Slide1} from './Slaide1.png'
export {default as Slide3} from './Slaide3.png'
export {default as Slide4} from './Slaide4.png'
export {default as Slide5} from './Slaide5.png'
export {default as Slide6} from './Slaide6.png'
export {default as Slaq}  from './Slaq.png'
export {default as Longer}  from './Longer.png'
export {default as Slod}  from './slod.png'
export {default as Slaide7} from './Erexaner.png'
export const bgImageBanner = require('../images/kaput.png')
export const KidsImage = require('../images/erexaner1245.png')
export {default as Frame} from './Frame.png'











