import React, { useEffect } from 'react'
import BirtDayHeader from './BirtDayHeader/BirtDayHeader'
import BirthDayFixedButons from './BirthDayFixedButons/BirthDayFixedButons'
import Main_Invitation from './Main_Invitation/Main_Invitation'
import { sectiosData } from '../../dataFolder/data'
import Main_Restaurant from './Main_Restaurant/Main_Restaurant'
import BirthDayLinkModal from './BirthDayLinkModal/BirthDayLinkModal'
import { birthDayChangeEdit, birthDayChangeLAngModalSelector, birthDayChangeLinkModalSelector, birthDayChangeView } from '../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice'
import { useDispatch, useSelector } from 'react-redux'
import Footer from './Footer/Footer'
import Mini_Footer from './Mini_Footer/Mini_Footer'
import { selectProjectData } from '../../store/slices/GetProjectSlice/GetProjectSlice'

function BirthDay() {
  const linkView = useSelector(birthDayChangeLinkModalSelector)
  const birthDayLangModal = useSelector(birthDayChangeLAngModalSelector)
  const respProjectData = useSelector(selectProjectData);


  const dispatch = useDispatch()

  useEffect(() => {
    if (birthDayLangModal === false && localStorage.getItem('langModalBirth') === 'true') {
      dispatch(birthDayChangeEdit())

    }

    setTimeout(() => {
        localStorage.removeItem('langModalBirth');
    }, 2000);

}, [birthDayLangModal, dispatch]);

console.log(respProjectData?.data?.sections, 'respProjectData932');

  return (
    <div className='birth_day'>
        <BirtDayHeader/>
        {
         respProjectData?.data?.sections ?( respProjectData?.data?.sections.map((section, index) => {
           if(section.section_number === '1'){
            return <Main_Invitation key={index} section={section}/>
           }
           else if(section.section_number === '2'){
            return <Main_Restaurant key={index} section={section}/>
           }
        })) : (
          <>
             <Main_Invitation />
             <Main_Restaurant />
          </>
        )
        }

        <Footer/>
        <Mini_Footer/>
        <BirthDayFixedButons/>
        {linkView && <BirthDayLinkModal/>}
    </div>
  )
}

export default BirthDay