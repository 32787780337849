import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios";

export const getProject = createAsyncThunk(
    'notification/getNotification',
    async (token, thunkAPI) => {
      try {
        const config = {
          method: 'get',
          url: `event-result?token=${token}`,
        };
  
        const response = await instance(config);
        localStorage.setItem('lang', response.data.data.lang);
        
        const pathname = window.location.pathname.split('/')[2];
        const result = window.location.pathname.split('/')[1];
        if (result !== response.data.data.lang) {
          window.location.pathname = `/${response.data.data.lang}/${pathname}/`;
        }

        return response?.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    },
  );
  