import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Main_Invitation.css';
import { invitation_1, invitation_2, invitation_3 } from '../../../images/BirthDayImg/Images';
import { sectiosData } from '../../../dataFolder/data';
import { useDispatch, useSelector } from 'react-redux';
import { birthDayChangeInfoSelector, birthDayChangeSelector, birthDayInfoSelector, changeBirthDayFeedback, changeBirthDayInvitationName, changeBirthDaySection1_time, changeBirthDaySection_1_addres, changeBirthDaySection_1_images, changeBirthDaySection_1_location_name, changeBirthDaySection_1_text } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { galeriaIcon } from '../../../iconsFolder/icons';
import { convertToBase64 } from '../../../utils/helperFunck';
import BirthDayOneImage from '../BirthDayOneImage/BirthDayOneImage';

function Main_Invitation({ section }) {
    const { t, i18n } = useTranslation();
    const [invitationDisplay, setInvitationDisplay] = useState(false);
    const [section1Time, setSection1Time] = useState('');
    const [section1Location_name, setSection1Location_name] = useState('');
    const [section1Address, setSection1Address] = useState('');
    const [invitationName, setInvitationName] = useState('');
    const [section1_text, setSection1_text] = useState('');
    const [birthDayFeedBack, setBirthDayFeedBack] = useState('');
    const invitationRef = useRef(null);
    const birthDayInfo = useSelector(birthDayInfoSelector);
    const isChange = useSelector(birthDayChangeInfoSelector);
    const respProjectData = useSelector(selectProjectData);
    const birtDayKeys = useSelector(birthDayChangeSelector)

    const [file, setFile] = useState(null);
    const dispatch = useDispatch()



    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (invitationRef.current && offset > invitationRef.current.offsetTop - 500) {
                setInvitationDisplay(true);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);


    useEffect(() => {
        dispatch(changeBirthDaySection1_time(section1Time))
    }, [section1Time, dispatch]);

    useEffect(() => {
        dispatch(changeBirthDaySection_1_addres(section1Address))
    }, [section1Address, dispatch]);

    useEffect(() => {
        dispatch(changeBirthDaySection_1_location_name(section1Location_name))
    }, [section1Location_name, dispatch]);

    useEffect(() => {
        dispatch(changeBirthDayInvitationName(invitationName))
    }, [invitationName, dispatch]);

    useEffect(() => {
        dispatch(changeBirthDaySection_1_text(section1_text))
    }, [section1_text, dispatch]);


    useEffect(() => {
        dispatch(changeBirthDayFeedback(birthDayFeedBack))
    }, [birthDayFeedBack, dispatch]);
    
    console.log(section, 'section888');
    

    return (
        <div className='main_invitation' ref={invitationRef}>
            <div className='container'>
                <div className='main_invitation_content'>
                    <div className='invintation_text'>
                        <h1>{t('main_invitation.0')}</h1>
                        {isChange === 'edit' ? <input className='main_invitation_input' defaultValue={t('main_invitation.1')} type="text" onChange={(e) => setSection1_text(e.target.value)} />
                            :
                            section ? <p>{section?.text}</p> :

                                birthDayInfo.section_1_text !== '' ? <p>{birthDayInfo.section_1_text}</p> :
                                    <div className='main_invitation_p_block'>
                                        <p>{t('main_invitation.1')}</p>
                                        <p>{t('main_invitation.2')}</p>
                                    </div>
                        }


                    </div>
                    <div className='invitation_box'>
                        <div className='invitation_image'>

                            {
                                invitationDisplay && section ? section.images.map((image, index) => (
                                    <div className={`invitation_image_div invitation_image_${index + 1} `} key={index}>
                                        <img src={image.path} alt="not found" />
                                    </div>
                                )) :
                                    <>
                                        <BirthDayOneImage idx={0}/>
                                        <BirthDayOneImage idx={1}/>
                                        <BirthDayOneImage idx={2}/>
                                    </>

                            }
                        </div>
                        {
                            invitationDisplay &&
                            <div className='invitation_blank'>
                                <h1>{t('main_invitation.3')}</h1>
                                <div>
                                    <span>{t('main_invitation.4')}</span>
                                    <span>{respProjectData?.data?.date || birthDayInfo?.date || '03 . 08 . 2024'}</span>
                                </div>
                                <div>
                                    <span>{t('main_invitation.5')}</span>
                                    {isChange === 'edit' ? <input type="text" className='birth_day_input birth_day_input_time' defaultValue={section?.time || birthDayInfo?.section_1_time || '12:00'} onChange={(e) => setSection1Time(e.target.value)} /> :
                                        <span>{section?.time || birthDayInfo?.section_1_time || '12:00'}</span>
                                    }
                                </div>
                                <div>
                                    <span>{t('main_invitation.6')}</span>
                                    {isChange === 'edit' ? <input type="text" className='birth_day_input birth_day_input_time' defaultValue={section?.location_name || birthDayInfo?.section_1_location_name || t('main_invitation.7')} onChange={(e) => setSection1Location_name(e.target.value)} /> :
                                        <span>{section?.location_name || birthDayInfo?.section_1_location_name || t('main_invitation.7')}</span>}
                                </div>
                                <div>
                                    {isChange === 'edit' ? <input type="text" className='birth_day_input birth_day_input_time' defaultValue={section?.address || birthDayInfo?.section_1_addres || t('main_invitation.8')} onChange={(e) => setSection1Address(e.target.value)} /> :
                                        <span className='adress_text'>{section?.address || birthDayInfo?.section_1_addres || t('main_invitation.8')}</span>}
                                    <span className='autor_text'>{t('main_invitation.9')}</span>
                                    {isChange === 'edit' ? <input style={{outline: birtDayKeys.isInvitationNameValid ? '2px solid red' : 'none'}} type="text" className='birth_day_input birth_day_input_time' defaultValue={respProjectData?.data?.invitation_name || birthDayInfo?.invitation_name || t('main_invitation.10')} onChange={(e) => setInvitationName(e.target.value)} /> : <span className='autor_text'>{respProjectData?.data?.invitation_name || birthDayInfo?.invitation_name || t('main_invitation.10')}</span>}
                                </div>

                                <div>
                                    {
                                        isChange === 'edit' && <input style={{outline: birtDayKeys.isFeedBackValid ? '2px solid red' : 'none'}} type="text" className='birth_day_input birth_day_input_feedback' placeholder='+374 00 000 000' onChange={(e) => setBirthDayFeedBack(e.target.value)}/> 
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main_Invitation
