import defaultImg1 from './defaultImg1.png';
import defaultImg2 from './defaultImg2.png';
import defaultImg3 from './defaultImg3.png';
import defaultImg4 from './defaultImg4.png';
import defaultImg5 from './defaultImg5.png';
import defaultImg6 from './defaultImg6.png';
export const defaultImagesList = [
   defaultImg1,
   defaultImg2,
   defaultImg3,
   defaultImg4,
   defaultImg5,
   defaultImg6,
];
