import {
  number0,
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
  number7,
  number8,
  number9,
  number11,
  number22,
  number33,
  number44,
  number55,
  number66,
  number77,
  number88,
  number99,
} from "./3d_models";
export const numberArray = [
  number0,
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
  number7,
  number8,
  number9,
  number0,
  number11,
  number22,
  number33,
  number44,
  number55,
  number66,
  number77,
  number88,
  number99,
];
