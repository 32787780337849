import React, { memo, useEffect, useState, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { background, balloons } from '../../../images/images';
import Timer from '../../timer/Timer';
import CustomCanvasWithSuspense from '../Custom/CustomCanvas';
import { numberArray } from '../../../asets/3d_model';
import { useDispatch, useSelector } from 'react-redux';
import {
  birthDayChangeInfoSelector,
  birthDayChangeSelector,
  birthDayInfoSelector,
  changeBirthDayAge,
  changeBirthDayDate,
} from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { sectiosData } from '../../../dataFolder/data';
import './BirtDayHeader.css';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';

function BirthDayHeader() {
  const { t } = useTranslation();
  const [birthDayDate, setBirthDayDate] = useState('');
  const [headerDisplay, setHeaderDisplay] = useState(false);
  const isChange = useSelector(birthDayChangeInfoSelector);
  const birthDayInfo = useSelector(birthDayInfoSelector);
  const [birthDayAge, setBirthDayAge] = useState('');
  const respProjectData = useSelector(selectProjectData);
  const birtDayKeys = useSelector(birthDayChangeSelector)
  const dispatch = useDispatch();

  console.log(birtDayKeys, '984');
  
  useEffect(() => {
    setTimeout(() => {
      setHeaderDisplay(true);
    }, 500);
  }, []);

  const handleAgeChange = (e) => {
    const value = e.target.value;
    if (/^\d{1,2}$/.test(value)) {
      startTransition(() => {
        setBirthDayAge(value);
      });
    } else {
      console.log('Invalid input');
    }
  };

  useEffect(() => {
    dispatch(changeBirthDayAge(birthDayAge));
  }, [birthDayAge, dispatch]);

  useEffect(() => {
    dispatch(changeBirthDayDate(birthDayDate));
  }, [birthDayDate, dispatch]);

  // const handleAgeMap = (data) => {
  //   const digitsArray = data?.toString().split('');
  //   return digitsArray.map((digit, index) => {
  //     const url = numberArray[parseInt(digit, 10)] || numberArray[0];
  //     return (
  //       <div className="test1" key={`${digit}-${index}-${Math.random()}`}>
  //         <CustomCanvasWithSuspense url={url} />
  //       </div>
  //     );
  //   });
  // };


  const handleAgeMap = (data) => {
    // Convert the input data to a string and split it into an array of digits
    const digitsArray = data?.toString().split('');
  
    // Iterate through the digits and adjust if consecutive digits are the same
    const adjustedDigitsArray = digitsArray.map((digit, index, array) => {
      if (index > 0 && digit === array[index - 1]) {
        return (parseInt(digit, 10) + 10).toString();
      }
      return digit;
    });
  
    // If adjustedDigitsArray is empty, use ['5', '0']
    const finalDigitsArray = adjustedDigitsArray.length > 0 ? adjustedDigitsArray : ['3', '7'];
  
    // Map the finalDigitsArray to create the required JSX
    return finalDigitsArray.map((digit, index) => {
      const url = numberArray[parseInt(digit, 10)] || numberArray[0];
      
      return (
        <div className="test1" key={index}>
          <CustomCanvasWithSuspense url={url} />
        </div>
      );
    });
  };
  
  console.log(birthDayInfo,888);
  
  return (
    <div className="header" style={{ backgroundImage: `url(${background})` }}>
      <div className="container">
        <div className="header_content">
          {headerDisplay && (
            <div className="birthday_box">
              <h1>{t('birthDayHeaderTitle.0')}</h1>
              <h2>{t('birthDayHeaderTitle.1')}</h2>
              {isChange === 'view' ? (
                <div className="age">
                  {respProjectData?.data?.age
                    ? handleAgeMap(respProjectData?.data?.age)
                    : handleAgeMap(birthDayInfo.age)}
                </div>
              ) : (
                
                <input
                  style={{outline: birtDayKeys.isAgeValid ? '2px solid red' : 'none'}}
                  type="text"
                  className="birth_day_input"
                  defaultValue={respProjectData?.data?.age || birthDayAge}
                  onChange={handleAgeChange}
                  placeholder={respProjectData?.data?.age || birthDayAge || '37'}
                />
              )}
              {isChange === 'edit' ? (
                <input
                  style={{outline: birtDayKeys.isDateValid ? '2px solid red' : 'none'}}
                  className="birth_day_input"
                  type="date"
                  value={birthDayInfo?.date || '2024-12-24'}
                  onChange={(e) => setBirthDayDate(e.target.value)}
                />
              ) : (
                <span>{respProjectData?.data?.date || birthDayInfo?.date || '03 . 08 . 2024'}</span>
              )}
              <Timer allInfo={birthDayInfo} />
              {headerDisplay && (
                <div className="balloons">
                  <img src={balloons} alt="not found" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(BirthDayHeader);
