import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './HomeChangeLang.css'
import { globIcon } from '../../../iconsFolder/icons';
import { useNavigate } from 'react-router-dom';

function HomeChangeLang() {
    const leng = localStorage.getItem('lang')
  
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      const prevLng = localStorage.getItem('lang')
      const pathname = window.location.pathname
      const result = pathname.replace('/' + prevLng, '/' + lng)
      localStorage.setItem('lang', lng)
  
      window.location.href = result

      
       
    };
  
  
    useEffect(() => {
      if (leng === null) {
        localStorage.setItem('lang', 'am')
      }
    }, [leng])
  return (
    <div className='home_change_lang'>
        <span className='home_change_lang_icon'>{globIcon}</span>
        <div className="lengs">
          <span style={{borderBottom: leng === 'am' ? '1px solid white' : 'none'}} onClick={() => changeLanguage('am')}>Arm</span>
          <span style={{borderBottom: leng === 'ru' ? '1px solid white' : 'none'}} onClick={() => changeLanguage('ru')}>Rus</span>
          <span style={{borderBottom: leng === 'en' ? '1px solid white' : 'none'}} onClick={() => changeLanguage('en')}>Eng</span>
        </div>
    </div>
  )
}

export default HomeChangeLang