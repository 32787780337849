import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { birthDayChangeLAngModal } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import './BirthDayLangModal.css'

function BirthDayLangModal() {
    const leng = localStorage.getItem('lang')

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const prevLng = localStorage.getItem('lang')
        const pathname = window.location.pathname
        const result = pathname.replace('/' + prevLng, '/' + lng)
        localStorage.setItem('lang', lng)

        window.location.href = result

        dispatch(birthDayChangeLAngModal())
        localStorage.setItem('langModalBirth', 'true');
    };


    useEffect(() => {
        if (leng === null) {
            localStorage.setItem('lang', 'am')
        }
    }, [leng])
    return (
        <div className='birth_day_lang_modal'>
            <div className='birth_day_lang_modal_block'>
                <p className='birth_day_lang_modal_block_title'>Language</p>
                <h3 className='birth_day_lang_modal_block_subtitle'>Select the language of the invitation card</h3>
                <label onClick={() => changeLanguage('am')}>
                    <input type="radio" name="language" />
                    <span>Armenian</span>
                </label>

                <label onClick={() => changeLanguage('ru')}>
                    <input type="radio" name="language" />
                    <span>Russian</span>
                </label>

                <label onClick={() => changeLanguage('en')}>
                    <input type="radio" name="language" />
                    <span>English</span>
                </label>
            </div>
        </div>
    )
}

export default BirthDayLangModal