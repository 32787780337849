import React, { useEffect, useState } from 'react'
import { invitationImages, resturantImages } from '../../../images/BirthDayImg/Images'
import { birthDayChangeInfoSelector, changeBirthDay_section_2_images } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { convertToBase64 } from '../../../utils/helperFunck';
import { galeriaIcon } from '../../../iconsFolder/icons';
import './ResturantOneImage.css'

function ResturantOneImage({idx}) {
    const dispatch = useDispatch();
    const isChange = useSelector(birthDayChangeInfoSelector);
    const [file, setFile] = useState(null);

        const handleChange = (e) => {
            setFile(e.target.files[0]);
        };


    useEffect(() => {
        if (file) {
            const fileBlob = new Blob([file], { type: file.type });
            dispatch(changeBirthDay_section_2_images({ index: idx, file: fileBlob }));
        }
    }, [file, dispatch, idx]);

    const imageURL = file ? URL.createObjectURL(file) : resturantImages[idx];

  return (
    <div className={`resturant_image_div item-${idx+1}`}>
        <img src={imageURL} alt="not found" />
        {isChange === 'edit' && <div className='image_galeria'>
        <label>
            <div className='galeria_icon'>{galeriaIcon}</div>
            <input style={{ display: 'none' }} type="file" onChange={handleChange} />
        </label>
    </div>}
    </div>
  )
}

export default ResturantOneImage