import React from 'react'
import './HomeFeedBackModal.css'
import { telIconFeedback } from '../../../iconsFolder/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectTarifObj, setFeedback, tarifFeedbackModalClose } from '../../../store/slices/HomePageSlice/HomePageSlice'
import { postFeedback_Tarif } from '../../../store/slices/HomePageSlice/HomePageApi'
import { useTranslation } from 'react-i18next'

function HomeFeedBackModal() {
    const dispatch = useDispatch()
    const tarifObj = useSelector(selectTarifObj)
    const {t} = useTranslation()
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (tarifObj.tariff_id && tarifObj.feedback) {
            dispatch(postFeedback_Tarif(tarifObj)).then((res) => {
                if (res.payload.data) {
                    window.location.href = res.payload.data.redirect_url
                }
            })
        }

    }

  return (
    <div className='home_feedback_modal'>
        <div className='home_feedback_modal_block'>
            <h3>{t('homePageTarifSectionModal')}</h3>
            <form className='home_feedback_modal_block_form' onSubmit={handleSubmit}>
                <div className='home_feedback_modal_block_form_input_div'>
                    <input type="text" placeholder='+374 00 000 000' onChange={(e) => dispatch(setFeedback(e.target.value))}/>
                    <span>{telIconFeedback}</span>
                </div>
                <button>{t('tiketsButton.3')}</button>
            </form>
            <span className="home_feedback_x_mark" onClick={() => dispatch(tarifFeedbackModalClose())}>X</span>
        </div>
    </div>
  )
}

export default HomeFeedBackModal