import React, { useEffect, useState } from 'react'
import './HomePageTemplates.css'
import { homePageLoading, homePageSelector } from '../../../store/slices/HomePageSlice/HomePageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getHomePage } from '../../../store/slices/HomePageSlice/HomePageApi';
import { Loader } from '@react-three/drei';
import LoaderProj from '../../Loader/LoaderProj';
import { useTranslation } from 'react-i18next';

function HomePageTemplates({ setSelectedCategories, selectedCategories }) {
    const respHomeData = useSelector(homePageSelector);
    const respLoading = useSelector(homePageLoading);
    const leng = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'am';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();


    const handleCategoryChange = (categoryId) => {
        setSelectedCategories(prevSelected => {
            const isSelected = prevSelected.includes(categoryId);
            if (isSelected) {
                return prevSelected.filter(id => id !== categoryId);
            } else {
                return [...prevSelected, categoryId];
            }
        });
    };


    return (
        <div className='home_page_templates'>
            <div className='hoem_page_templates_block_top'>
                        <h3 className='hoem_page_templates_block_title'>{t('homePageTemplatesTitles.0')}</h3>
                        <p className='hoem_page_templates_block_text'>{t('homePageTemplatesTitles.1')}</p>
                    </div>
            {respLoading === 'pending' ? <LoaderProj /> : <div className="container">
                <div className="home_page_categories">
                    <h3 className='home_page_categories_title'>{t('homePageCategoryTitle')}</h3>
                    {respHomeData?.data?.categories.map((category) => (
                        <div key={category.id} className="category_home">
                            <label onClick={() => handleCategoryChange(category.id)}>
                                <input type="checkbox" checked={selectedCategories.includes(category.id)} onChange={() => { }} />
                                <span>{category.name}</span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="hoem_page_templates_block">
                    <div className="hoem_page_templates_block_items">
                        {respHomeData?.data?.templates.map((product) => (
                            <div className="box" key={product.id}>
                                <div className="img-box">
                                    <img src={product.image_path} alt={product.name} />
                                </div>
                                <div>
                                    <p className="puk">{product.name}</p>
                                    <p className="puk">{product.price} AMD</p>
                                </div>
                                <div className="detail-box">
                                    <button className='detail_view' onClick={() => navigate(`/${leng + product.route}`)}>{t('projectButtons.2')}</button>

                                    <button className='detail_create' onClick={() => window.location.href = `${window.location.origin}/${leng + product.route}`}>{t('projectButtons.1')}</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default HomePageTemplates