import React, { useEffect, useState } from "react";
import "./HomePage.css";
import { useDispatch, useSelector } from "react-redux";
import { getHomePage } from "../../store/slices/HomePageSlice/HomePageApi";
import HomePageSlider from "./HomePageSlider/HomePageSlider";
import HomePageTemplates from "./HomePageTemplates/HomePageTemplates";
import HomePagePromoCode from "./HomePagePromoCode/HomePagePromoCode";
import HomePageQrSection from "./HomePageQrSection/HomePageQrSection";
import HomePageCommentSection from "./HomePageCommentSection/HomePageCommentSection";
import HomePageTarifSection from "./HomePageTarifSection/HomePageTarifSection";
import HomeContactModal from "./HomeContactModal/HomeContactModal";
import { selectContactModal, selectTarifFeedbackModal } from "../../store/slices/HomePageSlice/HomePageSlice";
import HomeFeedBackModal from "./HomeFeedBackModal/HomeFeedBackModal";
import HomePageMiniFooter from "./HomePageMiniFooter/HomePageMiniFooter";




function HomePage() {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const isOpenContactModal = useSelector(selectContactModal)
  const isOpenFeedBackModal = useSelector(selectTarifFeedbackModal)
  console.log(isOpenFeedBackModal,'77++');

  useEffect(() => {
    dispatch(getHomePage([]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHomePage(selectedCategories));
  }, [selectedCategories, dispatch]);


  useEffect(() => {
    if (isOpenContactModal || isOpenFeedBackModal) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }
  }, [isOpenContactModal, isOpenFeedBackModal]);

  return (
    <div className="home_page">
      <HomePageSlider/>
      <HomePageTemplates {...{setSelectedCategories, selectedCategories}}/>
      <HomePagePromoCode/>
      <HomePageQrSection/>
      <HomePageCommentSection/>
      <HomePageTarifSection/>
      <HomePageMiniFooter/>
      {isOpenContactModal && <HomeContactModal/>}
      {isOpenFeedBackModal && <HomeFeedBackModal/>}
    </div>
  );
}

export default HomePage;
